<template>
  <div>
    <van-nav-bar
        title="认证"
        :border="false"
        left-arrow
        @click.native="$router.back()"
    >
    </van-nav-bar>
    <van-form @submit="onSubmit">
      <van-field
          readonly
          clickable
          name="picker"
          :value="type.text"
          label="上传数据类型"
          placeholder="点击选择类型"
          @click="showPicker = true"
      />
      <van-field v-if="type.value==1" name="uploader" label="图片合集">
        <template #input>
          <van-uploader @delete="remove2" :after-read="afterRead" v-model="uploader" />
        </template>
      </van-field>
      <van-field v-if="type.value==2" name="uploader" label="视频">
        <template #input>
          <van-uploader :max-count="1" accept="video/*" :after-read="afterRead2" />
        </template>
      </van-field>
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="list"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>
      <div style="margin: 16px;">
        <van-button @click="onSubmit" round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {cashWithdrawal, getUploadUrl, uploadImg,verifyUser} from "@/api/user";
import {Toast} from "vant";
import * as Minio from "minio";

export default {
  name: "verify",
  data() {
    return {
      type:{
        value: 1,
        text: '图片'
      },
      list: [
        {
          value: 1,
          text: '图片'
        },
        {
          value: 2,
          text: '视频'
        }
      ],
      showPicker: false,
      message: '',
      imgUrl: '',
      key: null,
      uploader: [],
      videoUrl:'',
      imgList:[]
    }
  },
  created() {

  },
  methods: {
    onConfirm(value) {
      this.type.value = value.value;
      this.type.text = value.text;
      this.showPicker = false;
    },
    onSubmit() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      verifyUser({"dataType":this.type.value, "pics": "upload/s/bbt3891.png,upload/s/3345.png", "videoUrl":this.videoUrl}).then((res) => {
        if (res.code == 0) {

        }
      })
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      uploadImg({file: file}).then((res) => {
        if (res.code == 200) {
          this.uploader.push({
            url:res.result
          });
          this.imgList.push(res.result)
        } else {

        }
      })
    },
    afterRead2(file, path, p) {
      // 此时可以自行将文件上传至服务器
      getUploadUrl({fileName: file.file.name, mode: '1'}).then(async (res) => {
        if (res.code == 0) {
          let data = res.result
          const dataobj = {
            endPoint: data.ossConfig.endpoint,
            port: data.ossConfig.port,
            useSSL: false,
            accessKey: data.ossConfig.accessKey,
            secretKey: data.ossConfig.secretKey,
            secure: false
          }
          const minioClient = new Minio.Client(dataobj)
          var metaData = {
            'Content-Type': file.file.type,
          }
          let fr = new FileReader();//用FileReader 读取文件流
          fr.readAsArrayBuffer(file.file);
          fr.addEventListener("loadend", e => {
            minioClient.putObject('video', data.ossConfig.objectName, new Buffer(e.target.result), file.file.size, function (err, objInfo) {
              if (err) {
                return console.log(err); // err should be null
              }
            })
          })
        }
      })
      getUploadUrl({fileName: file.file.name}).then(async (res) => {
        this.uploadProgress(res.result.uploadUrl, file.file)
      })
    },
    uploadProgress(putUrl, file) {
      let http = new XMLHttpRequest();
      http.upload.addEventListener('progress', (e) => {
        const progress = (e.loaded / e.total) * 100;
        console.log(`上传进度: ${progress.toFixed(2)}%`);
        this.progress = progress.toFixed(2)
      }, false);

      http.onload = () => {
        if (http.status === 200 && http.status < 300 || http.status === 304) {

          try {
            //监听http的完成事件，并执行上传完成的监听函数
            const result = http.responseURL
            this.videoUrl	 = result
            console.log(result)
          } catch (error) {
            //监听错误
            console.error(error)
          }
        }
      }

      http.open("PUT", putUrl, true);
      http.send(file);
    },
    remove2(item,index){
      console.log(item,'itemad',index)
      this.imgList.splice(index,1)
    },
    remove() {
      this.progress = 0
    },
  },
}
</script>

<style scoped>

</style>